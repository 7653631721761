import React, { FC, ReactNode } from 'react';
import { Link } from 'gatsby';
import { ButtonUnstyled } from '@lambdacurry/component-library';

export interface NavItemProps {
  name: string;
  url: string;
  newTab?: boolean;
  isSubitem?: boolean;
  children?: ReactNode;
}

export const NavItem: FC<NavItemProps> = ({ name, url, newTab, isSubitem, children }) => (
  <li className={`nav-items__${isSubitem ? 'subitem' : 'item'}`} key={name}>
    <ButtonUnstyled
      className="block"
      as={buttonProps => (
        <Link to={url} target={newTab ? '_blank' : '_self'} activeClassName="active" {...buttonProps} />
      )}
    >
      {name}
    </ButtonUnstyled>
    {children}
  </li>
);
