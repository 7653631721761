import React, { FC } from 'react';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { convertToBgImage, IBgImageProps } from 'gbimage-bridge';
import { default as GatsbyBackgroundImage } from 'gatsby-background-image';

export interface BackgroundImageProps extends IBgImageProps {
  imageData: ImageDataLike;
}

export const BackgroundImage: FC<BackgroundImageProps> = ({ children, imageData }) => {
  const image = getImage(imageData);
  const bgImage = convertToBgImage(image);

  return (
    <GatsbyBackgroundImage Tag="section" {...bgImage} preserveStackingContext>
      {children}
    </GatsbyBackgroundImage>
  );
};
