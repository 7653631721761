import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { EventsListSectionData } from '../Sections.types';
import { Container, EventsList, Grid, Section, SectionProps } from '../..';
import { CalendarEventQuery } from '../../../types';
import { mapEventsToMonths } from '../../../util/helpers';
import { months } from '../../../util/constants';

import './events-list-section.scss';

export interface EventsListProps extends SectionProps {
  sectionData: EventsListSectionData;
}

export const EventsListSection: FC<EventsListProps> = ({ className, sectionData }) => {
  const allCalendarEventQuery = graphql`
    query allCalendarEventQuery {
      allCalendarEvent {
        events: nodes {
          ...CalendarEventFields
        }
      }
    }
  `;

  const data = useStaticQuery<CalendarEventQuery>(allCalendarEventQuery);
  const { events } = data.allCalendarEvent;

  if (!events || events.length < 1)
    return (
      <Section>
        <Container>
          <h2 className="text-blue">No events</h2>
          <p>There are no events scheduled at this time. Please check back later.</p>
        </Container>
      </Section>
    );

  const eventsByMonth = mapEventsToMonths(events);

  return (
    <Section className={classNames('events-list-section', className)}>
      {eventsByMonth.map(eventsYear =>
        months.map(month => {
          const eventsMonth = eventsYear[month];

          if (!eventsMonth) return null;

          return (
            <Container key={month} className="events-list-section__month">
              <Grid>
                <div className="col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-4">
                  <h2 className="events-list-section__month__heading break-all hyphens-auto">{month}</h2>
                </div>

                <div className="col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-8">
                  <EventsList events={eventsMonth} />
                </div>
              </Grid>
            </Container>
          );
        })
      )}
    </Section>
  );
};
