import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import { EventsListItem } from './EventsListItem';
import { CalendarEventsList } from '../../types';

export interface EventsListProps extends HTMLAttributes<HTMLDivElement> {
  events: CalendarEventsList;
}

export const EventsList: FC<EventsListProps> = ({ events, className, ...props }) => (
  <div className={classNames('events-list', 'flex flex-col gap-20', className)} {...props}>
    {events.map(event => (
      <EventsListItem key={event.id} event={event} />
    ))}
  </div>
);
