import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonOutlinePrimary, Icon, Menu, MenuItems, MenuProps } from '@lambdacurry/component-library';
import { addToCalendar, CalenderApp } from '../../util/addToCalendar';
import { CalendarEvent } from '../../types';

export interface AddToCalendarButtonProps {
  event: CalendarEvent;
  menuProps?: Partial<MenuProps>;
  className?: string;
}

export const AddToCalendarButton: FC<AddToCalendarButtonProps> = ({ event, menuProps, className }) => {
  const handleAddToCalendarClick = (app: keyof typeof CalenderApp) =>
    addToCalendar(
      {
        id: event.id,
        title: event.summary,
        description: event.description,
        startDate: event.start.dateTime,
        endDate: event.end.dateTime,
        location: event.location,
        allDay: event.allDay
      },
      app
    );

  const calendars = [
    { label: 'Apple Calendar', type: 'other', icon: 'apple' },
    { label: 'Google', type: 'google', icon: 'google' },
    { label: 'Outlook', type: 'other', icon: 'windows' },
    { label: 'Outlook.com', type: 'outlook', icon: 'windows' },
    { label: 'Yahoo', type: 'yahoo', icon: 'yahoo' }
  ];

  const menuItems = calendars.map(({ label, type, icon }) => ({
    name: 'menu-item',
    startIcon: <Icon name={icon} aria-hidden="true" />,
    children: label,
    onClick: () => handleAddToCalendarClick(type as keyof typeof CalenderApp)
  })) as MenuItems;

  return (
    <Menu
      align="end"
      menuButton={
        <ButtonOutlinePrimary
          className={classNames('add-to-cart-button', className)}
          icon={<Icon name="chevronDown" />}
          iconPlacement="end"
        >
          Add Event to Calendar
        </ButtonOutlinePrimary>
      }
      {...menuProps}
      menuItems={menuItems}
    />
  );
};
