import { DateTime } from 'luxon';
import { formatDate } from './helpers';

export enum CalenderApp {
  'google',
  'yahoo',
  'outlook',
  'other'
}

export interface EventData {
  id: string;
  title: string;
  description?: string;
  startDate: Date;
  endDate: Date;
  location?: string;
  allDay?: boolean;
}

export const getRandomKey = () =>
  new Date().getTime().toString() + '_' + Math.floor(Math.random() * 999999999999).toString();

export const isMobile = () => {
  let mobile = false;

  // SEE: https://javascript.plainenglish.io/how-to-detect-a-mobile-device-with-javascript-1c26e0002b31
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.platform)
  )
    mobile = true;

  return mobile;
};

// See This thread for how the calendar date should be formatted:
// https://stackoverflow.com/questions/10551776/time-format-used-in-ics-file/10553050
export const formatDateForCalendar = (date: Date, app?: keyof typeof CalenderApp) =>
  DateTime.fromISO(new Date(date).toISOString()).toFormat(`yyyyLLdd'T'HHmmss`);

export const getGoogleCalendarUrl = (event: EventData) => {
  let calendarUrl = '';

  calendarUrl = `https://calendar.google.com/calendar/render`;
  calendarUrl += `?action=TEMPLATE`;
  calendarUrl += `&dates=${formatDateForCalendar(event.startDate)}`;
  calendarUrl += `/${formatDateForCalendar(event.endDate)}`;
  calendarUrl += `&location=${encodeURIComponent(event.location || '')}`;
  calendarUrl += `&text=${encodeURIComponent(event.title)}`;
  calendarUrl += `&details=${encodeURIComponent(event.description || '')}`;

  return calendarUrl;
};

export const getYahooCalendarUrl = (event: EventData) => {
  let calendarUrl = '';

  calendarUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20`;
  calendarUrl += `&title=${encodeURIComponent(event.title)}`;
  calendarUrl += `&st=${formatDateForCalendar(event.startDate)}`;
  calendarUrl += `&et=${formatDateForCalendar(event.endDate)}`;
  calendarUrl += `&desc=${encodeURIComponent(event.description || '')}`;
  calendarUrl += `&in_loc=${encodeURIComponent(event.location || '')}`;

  return calendarUrl;
};

export const getOutlookCalendarUrl = (event: EventData) => {
  let calendarUrl = '';

  calendarUrl = `https://outlook.live.com/owa/?rru=addevent`;
  calendarUrl += `&startdt=${event.startDate}`;
  calendarUrl += `&enddt=${event.endDate}`;
  calendarUrl += `&subject=${encodeURIComponent(event.title)}`;
  calendarUrl += `&location=${encodeURIComponent(event.location || '')}`;
  calendarUrl += `&body=${encodeURIComponent(event.description || '')}`;
  calendarUrl += `&allday=${!!event.allDay}`;
  calendarUrl += `&uid=${getRandomKey()}`;
  calendarUrl += `&path=${encodeURIComponent('/calendar/view/Month')}`;

  return calendarUrl;
};

export const getCalendarFileData = (event: EventData) => {
  let calendarData = '';

  calendarData += `BEGIN:VCALENDAR\n`;
  calendarData += `VERSION:2.0\n`;
  calendarData += `BEGIN:VEVENT\n`;
  calendarData += `URL:${document.URL}\n`;
  calendarData += `DTSTART:${formatDateForCalendar(event.startDate)}\n`;
  calendarData += `DTEND:${formatDateForCalendar(event.endDate)}\n`;
  calendarData += `SUMMARY:${event.title}\n`;
  calendarData += `DESCRIPTION:${event.description || ''}\n`;
  calendarData += `LOCATION:${event.location || ''}\n`;
  calendarData += `END:VEVENT\n`;
  calendarData += `END:VCALENDAR`;

  if (isMobile()) calendarData = encodeURI(`data:text/calendar;charset=utf8,${calendarData}`);

  return calendarData;
};

export const getAddToCalendarUrl = (event: EventData, app: keyof typeof CalenderApp) => {
  let calendarAppKey = Object.values(CalenderApp).includes(app) ? app : 'other';

  const apps = {
    google: (event: EventData) => getGoogleCalendarUrl(event),
    yahoo: (event: EventData) => getYahooCalendarUrl(event),
    outlook: (event: EventData) => getOutlookCalendarUrl(event),
    other: (event: EventData) => getCalendarFileData(event)
  };

  return apps[calendarAppKey](event);
};

export const downloadCalendarFile = (event: EventData) => {
  const data = getCalendarFileData(event);
  const blob = new Blob([data], { type: 'text/calendar;charset=utf-8' });
  const startDate = formatDate(event.startDate, {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  let filename = `CalendarEvent_`;
  filename += `${event.title.replaceAll(' ', '')}_`;
  filename += `${startDate.replaceAll('/', '').replaceAll(', ', '@').replaceAll(':', '').replaceAll(' ', '')}`;
  filename += `.ics`;

  // SEE: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const addToCalendar = (event: EventData, app: keyof typeof CalenderApp) => {
  if (!isMobile() && app === 'other') return downloadCalendarFile(event);

  return window.open(getAddToCalendarUrl(event, app), '_blank');
};
