import { Month } from '../types';

export const months = Object.values(Month);

export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};
