import React, { FC } from 'react';
import { Metadata as LCMetadata } from '@lambdacurry/gatsby-theme/src/components';
import { DrawerProvider, Snackbar } from '@lambdacurry/component-library';
import { Footer, Header, IconRegistry } from '..';
import { CollectionData } from '../../types';
import { useMetadata } from '../../hooks/useMetadata';
import { MetadataProps } from '@lambdacurry/gatsby-theme/src/types';

export interface LayoutProps {
  pageData?: Partial<CollectionData>;
  metadata?: MetadataProps;
}

export const Layout: FC<LayoutProps> = ({ children, pageData, ...props }) => {
  const metadata = useMetadata(pageData);

  return (
    <IconRegistry>
      <Snackbar.Provider>
        <DrawerProvider>
          <LCMetadata {...props.metadata} {...metadata} />
          <Header />
          <main>{children}</main>
          <Footer />
        </DrawerProvider>
      </Snackbar.Provider>
    </IconRegistry>
  );
};
