import React, { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { ButtonAccent } from '@lambdacurry/component-library';
import { CallToActionSectionData, ImageLayout } from '../Sections.types';
import { Container, Grid, Section, SocialLink } from '../..';
import InnerHTML from 'dangerously-set-html-content';

export interface CallToActionProps {
  className?: string;
  sectionData: CallToActionSectionData;
}

export const CallToActionSection: FC<CallToActionProps> = ({ className, sectionData }) => {
  const { button_link, button_text, content, image_text, layout, title, use_social_links } = sectionData;
  const image = getImage(sectionData?.image?.imageFile);
  const hasButton = !!button_link && !!button_text;
  const hasSocialLinks = !!use_social_links;

  return (
    <Section
      className={classNames(
        'call-to-action-section',
        'py-16 w-full bg-gradient-to-r text-white',
        {
          'from-blue to-purple': layout === ImageLayout.LEFT,
          'from-purple to-blue': layout === ImageLayout.RIGHT
        },
        className
      )}
    >
      <Container>
        <Grid className="items-center">
          {image && (
            <div
              className={classNames('col-span-12 md:col-span-10 md:col-start-2 lg:col-span-6 flex justify-center', {
                ['lg:col-start-1 order-2 lg:order-1']: !layout || layout === ImageLayout.LEFT,
                ['lg:col-start-7 order-2']: layout === ImageLayout.RIGHT
              })}
            >
              <GatsbyImage image={image} alt={image_text ?? title} />
            </div>
          )}

          <div
            className={classNames('col-span-12 md:col-span-10 md:col-start-2 lg:col-span-6', {
              ['lg:col-start-7 order:1 lg:order-2']: !layout || layout === ImageLayout.LEFT,
              ['lg:col-start-1 order-1']: layout === ImageLayout.RIGHT
            })}
          >
            <h2>{title}</h2>
            <InnerHTML html={content} />
            {!!hasButton && (
              <ButtonAccent className="mt-4" as={buttonProps => <Link {...buttonProps} to={button_link} />}>
                {button_text}
              </ButtonAccent>
            )}
            {hasSocialLinks && <SocialLink type="instagram" className={classNames({ 'mt-4': hasButton })} />}
          </div>
        </Grid>
      </Container>
    </Section>
  );
};
