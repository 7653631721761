import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { ButtonAccent } from '@lambdacurry/component-library';
import { Container } from '..';
import { default as HeroTransition } from '../../assets/svg/heroTransition.svg';
import { default as HeroBlob } from '../../assets/svg/heroBlob.svg';
import { PageData } from '../../types';

import './hero.scss';

export interface HeroProps {
  data: PageData;
}

export const Hero: FC<HeroProps> = ({ data }) => {
  const { hero_title, hero_subtitle, hero_third_subtitle, hero_button, hero_image, hero_type } = data;
  const image = getImage(hero_image?.imageFile);

  return (
    <section className={classNames('hero', 'relative overflow-hidden', { [`hero--${hero_type}`]: hero_type })}>
      {image && (
        <div className="hero-background absolute top-0 right-0 left-0 bottom-0">
          <GatsbyImage className="h-full w-full" image={image} alt="" role="presentation" aria-hidden="true" />
          <div className="hero-overlay absolute top-0 right-0 left-0 bottom-0 bg-gradient-to-br from-blue to-green opacity-80"></div>
          {/* Height with false prop passed in removes the height attribute, but causes a React error. We might need to refactor this section if we want to avoid that. */}
          <HeroTransition className="absolute bottom-0 left-0 right-0 w-full h-auto" role="presentation" />
        </div>
      )}

      <Container>
        {hero_type === 'standard' && (
          <div className="hero-content pt-16 pb-24 lg:pt-36 flex flex-col justify-end items-start">
            {hero_title && <h1>{hero_title}</h1>}
            {hero_subtitle && <p className="h3">{hero_subtitle}</p>}
            {hero_third_subtitle && <p className="lead">{hero_third_subtitle}</p>}
            {hero_button && (
              <ButtonAccent as={buttonProps => <Link {...buttonProps} to={hero_button.url} />}>
                {hero_button.text}
              </ButtonAccent>
            )}
          </div>
        )}

        {hero_type === 'overlay' && (
          <div className="hero-content relative flex justify-center items-center">
            <div className="hero-content-inner z-10 relative flex justify-center items-center">
              <div className="z-10 relative text-center">
                <StaticImage
                  src={'../../images/brand-watermark.png'}
                  alt=""
                  width={200}
                  height={200}
                  role="presentation"
                  placeholder="blurred"
                  className="hero-overlay-icon -mt-4 md:-mt-8"
                />
                {hero_title && <h1 className="h2 mt-4 md:mt-6 text-blue">{hero_title}</h1>}
              </div>
              <HeroBlob className="hero-blob z-0 absolute inset-0 w-full h-full" />
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};
