// Vendors
import React, { FC } from 'react';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { ButtonAccent, Form, Icon, InputText, useSnackbar } from '@lambdacurry/component-library';

// Styles
// import './subscription-form.scss';

// Types
export interface SubscriptionFormValues {
  email: string;
}

export const SubscriptionForm: FC<{}> = () => {
  const { addSnackbar } = useSnackbar();

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .nullable()
      .email('Please enter a valid email address.')
      .required('Please enter a valid email address.')
  });

  const handleSubmit = async ({ email }: SubscriptionFormValues) => {
    try {
      const response = await addToMailchimp(email);
      if (response.result === 'error') {
        if (response.msg.includes('is already subscribed'))
          return addSnackbar(`The email "${email}" is already subscribed.`, { variant: 'error' });
        return addSnackbar(response.msg, { variant: 'error' });
      }
      return addSnackbar(`The email "${email}" has been added to our mailing list.`, { variant: 'success' });
    } catch (error) {
      console.error(error);
      return addSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
    }
  };

  return (
    <Form
      className="subscription-form -mt-3 mb-8 max-w-xs"
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <>
          <InputText
            className="subscription-form__input dark-mode"
            name="email"
            variant="standard"
            placeholder="Enter your email address"
            formikProps={formikProps}
            prefix={<Icon name="mailOutline" />}
          />
          <ButtonAccent type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
            {formikProps.isSubmitting ? 'Subscribing...' : 'Subscribe'}
          </ButtonAccent>
        </>
      )}
    </Form>
  );
};
