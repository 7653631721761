import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { default as FooterBlob } from '../../assets/svg/footerBlob.svg';
import { Navigation, NavItems } from '../NavItems/NavItems';
import { Container, Grid, Logo, SocialLink, SubscriptionForm } from '..';
import { useGlobalSettings } from '../../hooks/useGlobalSettings';

const query = graphql`
  query FooterData {
    directus {
      footer {
        ...FooterNavigationItems
      }
    }
  }
`;

interface FooterQuery {
  directus: {
    footer: {
      navigation: Navigation;
    };
  };
}

export const Footer: FC<{}> = () => {
  const { email } = useGlobalSettings();
  const {
    directus: {
      footer: { navigation }
    }
  } = useStaticQuery<FooterQuery>(query);

  return (
    <footer className="w-full bg-gradient-to-r from-blue to-purple text-white py-16">
      <Container>
        <Grid>
          <div className="col-span-12 md:col-span-5 lg:col-span-7">
            <Grid>
              <div className="col-span-12 lg:col-span-7 flex justify-center md:justify-start">
                <div className="relative flex items-center justify-center" style={{ height: '181px', width: '279px' }}>
                  <FooterBlob className="z-0 absolute inset-0" />
                  <Logo />
                </div>
              </div>

              <div className="col-span-12 lg:col-span-5 mt-4 md:mt-2 md:px-4 lg:mt-0">
                <NavItems navigation={navigation} />
              </div>
            </Grid>
          </div>

          <div className="col-span-12 md:col-span-7 lg:col-span-5">
            <h4 className="h5">Keep up to date with our newsletter</h4>
            <SubscriptionForm />

            <h4 className="h5 mt-10">Contact Us</h4>
            <SocialLink type="instagram" className="-mt-1 mb-1" />
            <SocialLink type="email" />
          </div>
        </Grid>
      </Container>
    </footer>
  );
};
