import { graphql, useStaticQuery } from 'gatsby';
import { GlobalSettings } from '../types';

export interface GlobalSettingsQuery {
  directus: {
    global_settings: GlobalSettings;
  };
}

export const useGlobalSettings = (): GlobalSettings => {
  const globalSettingsData = useStaticQuery<GlobalSettingsQuery>(graphql`
    query GlobalSettingsQuery {
      directus {
        global_settings {
          ...GlobalSettingsFields
        }
      }
    }
  `);

  return globalSettingsData.directus.global_settings;
};
