import React, { FC } from 'react';
import './nav-items.scss';
import { NavItem } from './NavItem';

export interface NavItemData {
  name: string;
  url?: string;
  section_url?: string;
  open_in_new_tab?: boolean;
  children?: {
    navigation_item_id: NavItemData;
  }[];
}

export type Navigation = {
  item: NavItemData;
}[];

export interface NavItemsProps {
  navigation: Navigation;
}

const getNavItemProps = ({ url, section_url, open_in_new_tab, ...rest }: NavItemData) => ({
  ...rest,
  url: section_url || url || '',
  newTab: open_in_new_tab
});

export const NavItems: FC<NavItemsProps> = ({ navigation }) => (
  <nav className="nav-items">
    <ul>
      {navigation.map(({ item }) => {
        const { children, ...itemProps } = getNavItemProps(item);

        return (
          <>
            <NavItem {...itemProps}>
              {children && (
                <ul>
                  {children.map(({ navigation_item_id: subitem }) => {
                    const subitemProps = getNavItemProps(subitem);

                    return <NavItem key={subitem.name} isSubitem={true} {...subitemProps} />;
                  })}
                </ul>
              )}
            </NavItem>
          </>
        );
      })}
    </ul>
  </nav>
);
