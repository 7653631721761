import React, { FC, useRef, useState, HTMLAttributes } from 'react';
import Slider, { Settings as SlickSettings } from 'react-slick';
import classNames from 'classnames';
import { IconButton } from '@lambdacurry/component-library';

import { EventCard } from '..';
import { CalendarEvent } from '../../types';
import { isScreenMin } from '../../util/helpers';

import './events-carousel.scss';
import { breakpoints } from '../../util/constants';

export interface EventsCarouselProps extends HTMLAttributes<HTMLElement> {
  events: CalendarEvent[];
}

export const EventsCarousel: FC<EventsCarouselProps> = ({ events, className }) => {
  const sliderRef = useRef<Slider>(null);
  const [previousDisabled, setPreviousDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  const handleNextClick = () => {
    if (sliderRef?.current) sliderRef.current.slickNext();
  };

  const handlePrevClick = () => {
    if (sliderRef?.current) sliderRef.current.slickPrev();
  };

  const handleBeforeSliderChange = (currentSlide: number, nextSlide: number) => {
    const visibleSlides = isScreenMin('md') ? 2 : 1;

    setPreviousDisabled(nextSlide === 0);
    setNextDisabled(nextSlide + visibleSlides >= events.length);
  };

  const slickSettings: SlickSettings = {
    speed: 500,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    touchThreshold: 100,
    beforeChange: handleBeforeSliderChange,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={classNames('events-carousel', className)}>
      <Slider ref={sliderRef} className="events-carousel__slider" {...slickSettings}>
        {events.map((event, index) => (
          <EventCard key={`${event.id}-${index}`} className="flex-shrink-0" event={event} />
        ))}
      </Slider>

      <div className="events-carousel__arrows">
        <IconButton
          className="events-carousel__arrow"
          icon="arrowLeft"
          onClick={handlePrevClick}
          disabled={previousDisabled}
        />
        <IconButton
          className="events-carousel__arrow"
          icon="arrowRight"
          onClick={handleNextClick}
          disabled={nextDisabled}
        />
      </div>
    </div>
  );
};
