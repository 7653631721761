import React, { FC } from 'react';
import classNames from 'classnames';
import { default as GospelSVG } from '../../../assets/svg/gospel.svg';
import { default as RenewalSVG } from '../../../assets/svg/renewal.svg';
import { default as CitySVG } from '../../../assets/svg/city.svg';
import { default as MovementSVG } from '../../../assets/svg/movement.svg';
import { CoreValues, CoreValuesSectionData } from '../Sections.types';
import { Container, Grid, Section, SectionProps } from '../..';
import InnerHTML from 'dangerously-set-html-content';

export interface CoreValuesProps extends SectionProps {
  sectionData: CoreValuesSectionData;
}

const getSVGFromValue = (value: CoreValues) => {
  const SVGMap = {
    [CoreValues.Gospel]: <GospelSVG height="180" width="170" />,
    [CoreValues.Renewal]: <RenewalSVG height="180" width="190" />,
    [CoreValues.City]: <CitySVG height="180" width="149" />,
    [CoreValues.Movement]: <MovementSVG height="180" width="199" />
  };

  return SVGMap[value];
};

export const CoreValuesSection: FC<CoreValuesProps> = ({ className, sectionData }) => {
  const { title, value_1, value_2, value_3, value_4 } = sectionData;
  const values = [value_1, value_2, value_3, value_4];

  return (
    <Section className={classNames('core-values-section', 'lg:py-40 text-center lg:text-left', className)}>
      <Container>
        <h2 className="text-purple mb-12">{title}</h2>
        <Grid>
          {values.map((value, index) => {
            const coreValue = value.title.toLocaleUpperCase() as CoreValues;

            return (
              <div
                key={index}
                className="col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-6 xl:flex items-center xl:mb-4"
              >
                <div className="flex flex-col items-center lg:items-start xl:items-center xl:mr-8">
                  <div className="mb-4">{getSVGFromValue(coreValue)}</div>
                  <h3
                    className={classNames('uppercase font-bold text-lg mb-4 xl:mb-0', {
                      'text-green': coreValue === CoreValues.Gospel,
                      'text-yellow': coreValue === CoreValues.Renewal,
                      'text-red': coreValue === CoreValues.City,
                      'text-blue': coreValue === CoreValues.Movement
                    })}
                  >
                    {value.title}
                  </h3>
                </div>
                <InnerHTML html={value.content} />
              </div>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};
