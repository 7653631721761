import { graphql, useStaticQuery } from 'gatsby';
import { MetadataProps } from '@lambdacurry/gatsby-theme/src/types';
import { CollectionData } from '../types';
import { useGlobalSettings } from './useGlobalSettings';

export const useMetadata = (pageData?: Partial<CollectionData>): MetadataProps => {
  const globalSettings = useGlobalSettings();

  const { metadata } = pageData || {};

  return {
    titleTemplate: globalSettings.title_template,
    defaultTitle: globalSettings.default_title,
    author: globalSettings.author,
    twitterSite: globalSettings.twitter_site,
    twitterCreator: globalSettings.twitter_creator,
    title: metadata?.title || pageData?.title,
    description: metadata?.description,
    ogTitle: metadata?.og_title,
    ogDescription: metadata?.og_description,
    ogImage: metadata?.og_image?.imageFile?.publicURL,
    twitterTitle: metadata?.twitter_title,
    twitterDescription: metadata?.twitter_description,
    twitterImage: metadata?.twitter_image?.imageFile?.publicURL
  };
};
