import React, { FC, HTMLAttributes } from 'react';
import { ButtonUnstyled, IconButton } from '@lambdacurry/component-library';
import classNames from 'classnames';
import { useGlobalSettings } from '../../hooks/useGlobalSettings';

export interface SocialLinkProps extends HTMLAttributes<HTMLElement> {
  type: 'instagram' | 'email';
}

export const SocialLink: FC<SocialLinkProps> = ({ type, className }) => {
  const { instagram_url, email } = useGlobalSettings();

  const options = {
    instagram: {
      url: instagram_url,
      text: 'Follow on Instagram',
      icon: 'instagram',
      ariaLabel: 'Instagram'
    },
    email: {
      url: `mailto:${email}`,
      text: email,
      icon: 'mailOutline',
      ariaLabel: 'Email'
    }
  };

  const link = options[type];

  return (
    <div className={classNames('social-link', 'flex -ml-1 gap-4 items-center hover:underline', className)}>
      <IconButton
        className="match-text-color -mr-2"
        icon={link.icon}
        aria-label={link.ariaLabel}
        as={buttonProps => <a {...buttonProps} href={link.url} target="_blank" />}
      />
      <ButtonUnstyled as={buttonProps => <a href={link.url} {...buttonProps} />}>{link.text}</ButtonUnstyled>
    </div>
  );
};
