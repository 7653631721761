import React, { FC } from 'react';
import classNames from 'classnames';
import { WysiwygSectionData } from '../Sections.types';
import { Container, Section, SectionProps } from '../..';
import InnerHTML from 'dangerously-set-html-content';

export interface WysiwygSectionProps extends SectionProps {
  sectionData: WysiwygSectionData;
}

export const WysiwygSection: FC<WysiwygSectionProps> = ({ className, sectionData }) => (
  <Section className={classNames('wysiwyg-section', className)}>
    <Container>
      <article>
        <InnerHTML className="prose lg:prose-xl mx-auto" html={sectionData.content} />
      </article>
    </Container>
  </Section>
);
