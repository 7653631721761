import React, { FC, HTMLAttributes } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import logoImg from '../../images/logo.png';

import './logo.scss';

export interface LogoProps extends HTMLAttributes<HTMLElement> {}

export const Logo: FC<LogoProps> = ({ className, ...props }) => (
  <span className={classNames('logo', className)} {...props}>
    <img src={logoImg} alt="Capitol City Church logo" />
  </span>
);
