import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Icon } from '@lambdacurry/component-library';
import { CalendarEvent } from '../../types';
import { getDisplayDate, getDisplayTime } from '../../util/helpers';
import { AddToCalendarButton } from '..';

export interface EventsListItemProps extends HTMLAttributes<HTMLElement> {
  event: CalendarEvent;
}

export const EventsListItem: FC<EventsListItemProps> = ({ event, className, ...props }) => {
  const startDate = getDisplayDate(event.start.dateTime);
  const startTime = getDisplayTime(event.start.dateTime);
  const endDate = getDisplayDate(event.end.dateTime);
  const endTime = getDisplayTime(event.end.dateTime);

  return (
    <article key={event.id} className={classNames('events-list__item', className)} {...props}>
      <header className="flex flex-col lg:flex-row mb-4 lg:mb-0 justify-between">
        <div>
          <div className="h3 text-primary">
            {startDate}
            {endDate !== startDate ? `- ${endDate}` : ''}
          </div>
          <h3 className="heading-secondary">{event.summary}</h3>
        </div>

        <div>
          <AddToCalendarButton event={event} />
        </div>
      </header>

      <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 mt-2">
        <div>
          <Icon name="clock" className="mr-1 text-blue" /> {startTime} - {endTime}
        </div>
        {event.location && (
          <div>
            <Icon name="pin" className="mr-1 text-blue" /> {event.location}
          </div>
        )}
      </div>

      <div className="mt-4">{event.description}</div>
    </article>
  );
};
