import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { ImageLayout, TextImageSectionData } from '../Sections.types';
import { Container, Grid, Section, SectionProps } from '../..';
import InnerHTML from 'dangerously-set-html-content';

export interface TextImageProps extends SectionProps {
  sectionData: TextImageSectionData;
}

export const TextImageSection: FC<TextImageProps> = ({ className, sectionData }) => {
  const { title, content, layout, image, secondary_image } = sectionData;
  const image1 = getImage(image?.imageFile);
  const image2 = getImage(secondary_image?.imageFile);

  return (
    <Section className={classNames('text-image-section', className)}>
      <Container>
        <Grid className="items-center">
          {image1 && (
            <div
              className={classNames('col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-6 flex justify-center', {
                ['lg:col-start-1 order-2 lg:order-1']: !layout || layout === ImageLayout.LEFT,
                ['lg:col-start-7 order-2']: layout === ImageLayout.RIGHT
              })}
            >
              {<GatsbyImage image={image1} alt={title} />}
            </div>
          )}
          <div
            className={classNames('col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-6', {
              ['lg:col-start-7 order:1 lg:order-2']: !layout || layout === ImageLayout.LEFT,
              ['lg:col-start-1 order-1']: layout === ImageLayout.RIGHT
            })}
          >
            {image2 && (
              <GatsbyImage
                className="mb-12 w-1/4 h-1/4 lg:w-1/3 lg:h-1/3"
                image={image2}
                alt={secondary_image.description || title}
              />
            )}
            <h2 className="text-primary">{title}</h2>
            <InnerHTML className="prose max-w-none" html={content} />
          </div>
        </Grid>
      </Container>
    </Section>
  );
};
