import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Button, ButtonUnstyled, Icon, IconButton, Menu, useDrawer } from '@lambdacurry/component-library';
import { SideNav } from '../SideNav/SideNav';
import { NavItem, NavSection } from '../NavItems/NavItems';
import { Container, Logo } from '..';

import './header.scss';

const query = graphql`
  query NavigationData {
    directus {
      header {
        id
        ...HeaderNavigationItems
      }
    }
  }
`;

interface HeaderQuery {
  directus: {
    header: {
      navigation: {
        item: NavItem | NavSection;
      }[];
    };
  };
}

export const Header: FC<{}> = () => {
  const {
    drawerActions: { toggleDrawer }
  } = useDrawer();

  const {
    directus: {
      header: { navigation }
    }
  } = useStaticQuery<HeaderQuery>(query);

  const navItems = navigation.map(({ item }) => ({ ...item }));

  return (
    <header className="header py-4 sm:py-5 lg:py-6">
      <Container className="flex justify-between">
        <ButtonUnstyled as={buttonProps => <Link to="/" aria-label="Home" {...buttonProps}></Link>}>
          <Logo className="header__logo" />
        </ButtonUnstyled>

        <div className="header-navigation flex justify-end items-center">
          <div className="hidden md:flex justify-end items-center">
            {navItems.map(item => {
              if ('children' in item && item.children?.length) {
                const { name, section_url, children } = item;
                console.log('children', children);

                return (
                  <Menu
                    key={name}
                    hover
                    menuButton={
                      <ButtonUnstyled
                        icon={<Icon className="text-gray" name="chevronDown" />}
                        iconPlacement="end"
                        className="header-menu-link inline-block py-4 mr-8"
                        as={buttonProps => (
                          <Link {...buttonProps} key={name} to={section_url} activeClassName="active" />
                        )}
                      >
                        {name}
                      </ButtonUnstyled>
                    }
                    menuItems={children
                      .filter(c => c?.navigation_item_id)
                      .map(({ navigation_item_id: child }) => {
                        const menuItem: any = {
                          name: 'menu-item',
                          href: child.url,
                          children: child.name
                        };
                        if (child.open_in_new_tab) menuItem.target = '_blank';

                        return menuItem;
                      })}
                  />
                );
              }
              const { name, url } = item;
              return (
                <ButtonUnstyled
                  key={name}
                  className="header-menu-link mr-8"
                  as={buttonProps => <Link {...buttonProps} key={name} to={url} activeClassName="active" />}
                >
                  {name}
                </ButtonUnstyled>
              );
            })}
          </div>
          <IconButton icon="threeDots" onClick={() => toggleDrawer({ name: 'side-nav' })} aria-label="toggle drawer" />
          <SideNav />
        </div>
      </Container>
    </header>
  );
};
