import { ImageDataLike } from 'gatsby-plugin-image';

export interface ImageData {
  id: number;
  description: string;
  imageFile: ImageDataLike;
}

export enum ImageLayout {
  LEFT = 'image-left',
  RIGHT = 'image-right'
}

export enum CoreValues {
  Gospel = 'GOSPEL',
  Renewal = 'RENEWAL',
  City = 'CITY',
  Movement = 'MOVEMENT'
}

export interface CoreValue {
  title: string;
  content: string;
}

export type SectionNames =
  | 'wysiwyg_section'
  | 'text_image_section'
  | 'call_to_action_section'
  | 'core_values_section'
  | 'events_list_section';

export interface BaseSectionData {
  id: number;
  collection: SectionNames;
}

export interface CallToActionSectionData extends BaseSectionData {
  button_link: string;
  button_text: string;
  content: string;
  image: ImageData;
  image_text: string;
  layout: ImageLayout;
  title: string;
  use_social_links: boolean;
}
export interface CoreValuesSectionData extends BaseSectionData {
  title: string;
  value_1: CoreValue;
  value_2: CoreValue;
  value_3: CoreValue;
  value_4: CoreValue;
}

export interface EventsListSectionData extends BaseSectionData {}

export interface EventsCarouselSectionData extends BaseSectionData {
  title: string;
  subtitle: string;
  content: string;
  events_url: string;
  events_url_text: string;
}

export interface WysiwygSectionData extends BaseSectionData {
  content: string;
}

export interface TextImageSectionData extends BaseSectionData {
  layout: ImageLayout;
  title: string;
  image: ImageData;
  secondary_image: ImageData;
  content: string;
}

export type SectionData =
  | BaseSectionData
  | CoreValuesSectionData
  | TextImageSectionData
  | CallToActionSectionData
  | EventsListSectionData;
