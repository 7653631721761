import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Icon } from '@lambdacurry/component-library';
import { AddToCalendarButton } from '..';
import { CalendarEvent } from '../../types';
import { formatDate, getDisplayTime } from '../../util/helpers';

export interface EventCardProps extends HTMLAttributes<HTMLElement> {
  event: CalendarEvent;
}

export const EventCard: FC<EventCardProps> = ({ className, event }) => {
  const { end, location, start, summary } = event;
  const startDate = start.dateTime;
  const startTime = getDisplayTime(startDate);
  const endTime = getDisplayTime(end.dateTime);

  return (
    <article
      className={classNames(
        'event-card',
        'bg-white rounded shadow-md p-6 md:p-8 lg:p-10 flex flex-col items-center',
        className
      )}
    >
      <header className="text-center">
        <p className="h3 uppercase text-green">{formatDate(startDate, { month: 'short' })}</p>
        <h2 className="uppercase text-blue">
          {formatDate(startDate, { day: '2-digit' })}
          <br />
          {formatDate(startDate, { weekday: 'short' })}
        </h2>
        <h3 className="heading-secondary">{summary}</h3>
      </header>

      <div className="flex-1">
        <div className="mt-4">
          <Icon name="clock" className="mr-2" />
          {startTime} - {endTime}
        </div>

        {location && (
          <div className="mt-4">
            <Icon name="pin" className="mr-2" />
            {location}
          </div>
        )}
      </div>

      <AddToCalendarButton className="mt-8" event={event} menuProps={{ align: 'center' }} />
    </article>
  );
};
