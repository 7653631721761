import React, { FC } from 'react';
import { Drawer, IconButton, useDrawer } from '@lambdacurry/component-library';
import { graphql, useStaticQuery } from 'gatsby';
import { Navigation, NavItems } from '../NavItems/NavItems';
import './side-nav.scss';

interface SideNavQuery {
  directus: {
    navigation: {
      sections: Navigation;
    };
  };
}

const query = graphql`
  query SideNavigationData {
    directus {
      navigation {
        ...SideNavigationItems
      }
    }
  }
`;

export const SideNav: FC<{}> = () => {
  const {
    drawerActions: { toggleDrawer }
  } = useDrawer();

  const {
    directus: { navigation }
  } = useStaticQuery<SideNavQuery>(query);

  return (
    <Drawer name="side-nav" className="side-nav">
      <div className="text-white bg-gradient-to-b from-purple to-blue py-4 pl-8 pr-4 h-screen overflow-y-auto">
        <div className="flex justify-end sticky top-0">
          <IconButton className="match-text-color" icon="close" onClick={() => toggleDrawer({ name: 'side-nav' })} />
        </div>
        <NavItems navigation={navigation.sections} />
      </div>
    </Drawer>
  );
};
