import { CalendarEventsByMonth, CalendarEventsList, CalendarEventsMonths, Month } from '../types';
import { breakpoints } from './constants';

export const isBrowser = (): boolean => typeof window !== 'undefined';

export const isScreenMin = (size: keyof typeof breakpoints): boolean => window.outerWidth >= breakpoints[size];

export const isScreenMax = (size: keyof typeof breakpoints): boolean => window.outerWidth <= breakpoints[size];

export const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) =>
  new Intl.DateTimeFormat('en-US', options).format(new Date(date)).toLowerCase();

export const getDisplayDate = (date: Date, options?: Intl.DateTimeFormatOptions) =>
  formatDate(date, { day: '2-digit', weekday: 'short', ...options });

export const getDisplayTime = (date: Date, options?: Intl.DateTimeFormatOptions) =>
  formatDate(date, { hour: 'numeric', minute: '2-digit', hour12: true, ...options });

export const mapEventsToMonths = (events: CalendarEventsList) =>
  events.reduce((acc, event) => {
    const startDate = event.start.dateTime;
    const startYear = parseInt(formatDate(startDate, { year: 'numeric' }), 10);
    const startMonth = formatDate(startDate, { month: 'long' }) as Month;

    if (!acc[startYear]) acc[startYear] = {} as CalendarEventsMonths;
    if (!acc[startYear][startMonth]) acc[startYear][startMonth] = [] as CalendarEventsList;

    acc[startYear][startMonth] = [...acc[startYear][startMonth], event];

    return acc;
  }, [] as CalendarEventsByMonth);
