import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { EventsCarouselSectionData } from '../Sections.types';
import { CalendarEventQuery } from '../../../types';
import { BackgroundImage, Container, EventsCarousel, Grid, Section, SectionProps } from '../..';

export interface EventsProps extends SectionProps {
  sectionData: EventsCarouselSectionData;
}

export const EventsCarouselSection: FC<EventsProps> = ({ className, sectionData }) => {
  const { content, events_url, events_url_text, subtitle, title } = sectionData;
  const calendarEventQuery = graphql`
    query calendarEventQuery {
      allCalendarEvent(sort: { fields: start___dateTime, order: ASC }, limit: 8) {
        events: nodes {
          ...CalendarEventFields
        }
      }

      backgroundImage: file(relativePath: { eq: "faded-green-swirl-background.png" }) {
        childImageSharp {
          gatsbyImageData(width: 2400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `;

  const data = useStaticQuery<CalendarEventQuery & { backgroundImage: ImageDataLike }>(calendarEventQuery);
  const { backgroundImage } = data;
  const { events } = data.allCalendarEvent;

  if (!events || events.length < 1) return null;

  return (
    <BackgroundImage imageData={backgroundImage}>
      <Section
        className={classNames(
          'events-carousel-section',
          'pt-16 pb-36 md:pb-32 lg:py-40 max-w-full overflow-x-hidden',
          className
        )}
      >
        <Container>
          {events && events.length > 0 && (
            <Grid>
              <div className="relative z-10 col-span-12 md:col-span-10 md:col-start-2 lg:col-span-4">
                <p className="h2 text-green">{title}</p>
                <h2 className="h3 heading-secondary">{subtitle}</h2>
                <p className="lead">{content}</p>
                <a className="underline" href={events_url}>
                  {events_url_text}
                </a>
              </div>
              <div className="relative z-0 col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8">
                <EventsCarousel className="mt-6 sm:mt-8 md:mt-10 lg:mt-0" events={events} />
              </div>
            </Grid>
          )}
        </Container>
      </Section>
    </BackgroundImage>
  );
};
