import { ImageDataLike } from 'gatsby-plugin-image';
import { SectionData, SectionNames } from '../components/Sections/Sections.types';

export enum Month {
  jan = 'january',
  feb = 'february',
  mar = 'march',
  apr = 'april',
  may = 'may',
  jun = 'june',
  jul = 'july',
  aug = 'august',
  sep = 'september',
  oct = 'october',
  nov = 'november',
  dec = 'december'
}

export interface GlobalSettings {
  id: number;
  title_template: string;
  default_title: string;
  author: string;
  twitter_site: string;
  twitter_creator: string;
  instagram_url: string;
  email: string;
}

export interface Metadata {
  id: number;
  title: string;
  description: string;
  og_title: string;
  og_description: string;
  og_image: {
    id: string;
    imageFile: {
      publicURL: string;
    };
  };
  twitter_title: string;
  twitter_description: string;
  twitter_image: {
    id: string;
    imageFile: {
      publicURL: string;
    };
  };
}

export interface CalendarEvent {
  id: string;
  summary: string;
  description: string;
  location: string;
  start: {
    dateTime: Date;
  };
  end: {
    dateTime: Date;
  };
  allDay: boolean;
}

export interface CalendarEventQuery {
  allCalendarEvent: {
    events: CalendarEvent[];
  };
}

export type CalendarEventsList = CalendarEvent[];

export type CalendarEventsMonths = {
  [key in Month]: CalendarEventsList;
};

export type CalendarEventsByMonth = CalendarEventsMonths[];

export interface CollectionData {
  id: string;
  title: string;
  metadata: Metadata;
  hero_title: string;
  hero_image: { imageFile: ImageDataLike };
}

export interface PageData extends CollectionData {
  url: string;
  hero_type: 'overlay' | 'standard';
  hero_subtitle: string;
  hero_third_subtitle: string;
  hero_button: {
    text: string;
    url: string;
  };
  sections: {
    item: SectionData;
    collection: SectionNames;
  }[];
}

export interface SermonData extends CollectionData {
  buzzsprout_id: string;
  buzzsprout_script: string;
  date_published: string;
  content: string;
}
