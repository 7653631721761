import React, { FC, Fragment } from 'react';
import { CallToActionSection } from './CallToAction/CallToActionSection';
import { CoreValuesSection } from './CoreValues/CoreValuesSection';
import { EventsCarouselSection } from './EventsCarouselSection/EventsCarouselSection';
import { EventsListSection } from './EventsListSection/EventsListSection';
import { TextImageSection } from './TextImageSection/TextImageSection';
import { WysiwygSection } from './WysiwygSection/WysiwygSection';
import {
  CallToActionSectionData,
  CoreValuesSectionData,
  EventsCarouselSectionData,
  EventsListSectionData,
  SectionData,
  TextImageSectionData,
  WysiwygSectionData
} from './Sections.types';

const SectionsMap = {
  call_to_action_section: (sectionData: SectionData) => (
    <CallToActionSection sectionData={sectionData as CallToActionSectionData} />
  ),
  core_values_section: (sectionData: SectionData) => (
    <CoreValuesSection sectionData={sectionData as CoreValuesSectionData} />
  ),
  events_list_section: (sectionData: SectionData) => (
    <EventsListSection sectionData={sectionData as EventsListSectionData} />
  ),
  events_carousel_section: (sectionData: SectionData) => (
    <EventsCarouselSection sectionData={sectionData as EventsCarouselSectionData} />
  ),
  text_image_section: (sectionData: SectionData) => (
    <TextImageSection sectionData={sectionData as TextImageSectionData} />
  ),
  wysiwyg_section: (sectionData: SectionData) => <WysiwygSection sectionData={sectionData as WysiwygSectionData} />
};

export interface SectionsProps {
  sections: SectionData[];
}

export const Sections: FC<SectionsProps> = ({ sections }) => (
  <main className="sections">
    {sections.map((sectionData, index) => {
      if (!SectionsMap[sectionData.collection]) {
        console.error(`A section for the "${sectionData.collection}" collection has not been created yet.`);
        return null;
      }

      return <Fragment key={`${sectionData.id}-${index}`}>{SectionsMap[sectionData.collection](sectionData)}</Fragment>;
    })}
  </main>
);
